import { observable, decorate, action, computed } from 'mobx';
import Auth from '../modules/auth/Auth';
import Consts from './../consts/Consts';
import QuestionsStore from './questions.store';
import FactStore from './fact.store';

class DialogStore {
    dialogId = '';
    // dangerRatingCounter = 0;
    localDialog = {};
    language = Consts.languages.he;
    dir = Consts.dir.he;
    client = "general"


    async insertToDialogAndFact(questionId, answerIndexs, questionText, answerText, questionKey, answerKey) {

        if (typeof answerIndexs == "number") answerIndexs = [answerIndexs];

        //insert to dialog
        let [res, err] = await Auth.superAuthFetch('/api/Dialogs/insertToDialogAQ', {
            method: 'POST',
            headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
            body: JSON.stringify({
                dialogId: this.dialogId,
                questionId,
                quizType: QuestionsStore.currentQuizType,
                answerIndexs,
                questionText,
                answerText
            })
        });
        if (err) { console.log("err insert dialog", err); return; }

        this.localDialog = res;


        //add fact
        let [addFactRes, addFactErr] = await Auth.superAuthFetch('/api/Facts/addNewFact', {
            method: 'POST',
            headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
            body: JSON.stringify({
                dialogId: this.dialogId,
                factType: questionKey,
                factValue: answerKey
            })
        });
        if (addFactErr) { console.log("err from add fact", addFactErr); return; }
    }


    get computedDialogId() { return this.dialogId; }
    set computedDialogId(id) { this.dialogId = id; }

    async setDialogExitStatus(exitStatus) {
        Auth.superAuthFetch('/api/Dialogs/setDialogExitStatus', {
            method: 'POST',
            headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
            body: JSON.stringify({
                dialogId: this.dialogId,
                exitStatus,
            })
        });
    }
}

decorate(DialogStore, {
    client: observable,
    dialogId: observable,
    computedDialogId: computed,
    insertToDialogAndFact: action,
    localDialog: observable,
    dir: observable,
    setDialogExitStatus: action
});

export default new DialogStore();