import React, { useState, useEffect } from 'react';
import { observer, inject } from 'mobx-react';
import Answer from '../Answer';
import Consts from './../../consts/Consts';
import Texts from '../../consts/Texts';
import '../../scss/_Container.scss';
import { toJS } from 'mobx';

function MultiSelectContainer(props) {
    const LANGUAGE = props.DialogStore.language;

    const [selected, setSelected] = useState([]);
    const [first, setFirst] = useState(true);

    useEffect(() => {
        setSelected([]);
        setFirst(true);
    }, [props.QuestionsStore.currentQuestion]);

    const selectAnswer = async (answerText, answerIndex, dangerRating, answerKey) => {

        let aSelected = [...selected];

        let aFound = false;
        for (let i = 0; i < aSelected.length; i++) {
            const s = aSelected[i];
            if (s.answerIndex === answerIndex) {
                aSelected.splice(i, 1);
                aFound = true;
            }
            if (!aFound && props.QuestionsStore.currentQuestion.answers[s.answerIndex].exclusive)
                aSelected.splice(i, 1);
        }
        if (!aFound) {
            if (props.QuestionsStore.currentQuestion.answers[answerIndex].exclusive)
                aSelected = [{ answerText, answerIndex, answerKey }];
            else aSelected.push({ answerText, answerIndex, answerKey });
        }


        setSelected([...aSelected]);
        setFirst(false);
    }

    const nextQuestion = async () => {
        let aSelected = [...selected];
        if (!aSelected.length) return;

        let sIndex = [];
        let sText = [];
        for (const s of aSelected) {
            sIndex.push(s.answerIndex);
            sText.push(s.answerText);
        }

        let answerKey = []
        for (let item in selected) {
            answerKey.push(selected[item].answerKey);
        }

        await props.DialogStore.insertToDialogAndFact(
            props.QuestionsStore.currentQuestion.question_id,
            sIndex,
            questionText,
            sText,
            props.QuestionsStore.currentQuestion.question_key,
            answerKey
        )

        props.QuestionsStore.getNextQuestion();
    }

    const getDAnswerTextSelected = (answerText, wordingFor, answerIndex) => {
        let dAnswerText, dSelected;
        //if we have 2 diffrent gender in the answer
        if (typeof answerText === "object" && answerText["0"]) {
            let fullAnswerText = '';
            answerText = toJS(answerText);
            for (let i of Object.keys(answerText)) {
                let genderText = props.DialogStore.localDialog.dialogData[wordingFor[i]];
                let partOfAnswerText = answerText[i][genderText]

                fullAnswerText = `${fullAnswerText} ${partOfAnswerText}`;
            }

            dAnswerText = fullAnswerText;
        }

        //if we have only one gender in the answer
        else if (typeof answerText === "object" && !answerText["0"]) {
            dAnswerText = answerText[props.DialogStore.localDialog.dialogData[wordingFor]];
        }

        // if (typeof answerText === "object") {
        //     dAnswerText = answerText[props.DialogStore.localDialog.dialogData[wordingFor]];
        // }

        else dAnswerText = answerText;

        let aSelected = [...selected];
        for (const s of aSelected) {
            if (s.answerIndex !== answerIndex) continue;
            dSelected = true;
        }

        return [dAnswerText, dSelected];
    }


    // if answer is an index of a chosen answer of previous question
    const getChosenAnswerByIndex = (answerText, index, answerKey) => {
        if (!props.DialogStore.localDialog.questionsAnswers || !props.QuestionsStore.isPreviousQuestion || !first) return false;
        for (const qa of props.DialogStore.localDialog.questionsAnswers) {
            if (qa.question_id !== props.QuestionsStore.currentQuestion.question_id) continue;

            for (const s of selected) {
                if (s.answerIndex === index) return true;
            }

            if (qa.answer_index.includes(index)) {
                setSelected([...selected, { answerText, answerIndex: index, answerKey }]);
                return true;
            }
        }
        return false;
    }

    const getQuestionText = () => {
        let question = props.QuestionsStore.currentQuestion.question_text;
        if (props.QuestionsStore.currentQuizType === Consts.pre_quiz) {
            question = question[LANGUAGE];
        }

        if (typeof question === "object") {
            let genderText = props.DialogStore.localDialog.dialogData[props.QuestionsStore.currentQuestion.wording_for]
            question = question[genderText];
        };

        return question;
    }

    let questionText = getQuestionText();//props.questionData.text[LANGUAGE];
    return (
        <div className={`MultiSelectContainer Container ${props.DialogStore.dir === "ltr" && "dir-ltr-align-left"}`}>
            <h1 className='mainTitle'>{questionText}</h1>
            <h3 className='subTitle'>{Texts.MULTI_SELECT_NOTE[props.DialogStore.language]}</h3>
            <div className='optionalAnswers'>
                {props.QuestionsStore.currentQuestion.answers.map((answer, index) => {
                    let [dAnswerText, dSelected] = getDAnswerTextSelected(answer.answer_text, answer.wording_for, index);

                    if (getChosenAnswerByIndex(dAnswerText, index, answer.answer_key)) dSelected = true;

                    return (
                        <div key={`${props.QuestionsStore.currentQuestion.question_id}${index}`}>
                            <Answer
                                answerText={dAnswerText}
                                setAnswerSelected={selectAnswer}
                                selected={dSelected}
                                dangerRating={answer.dangerRating || null}
                                answerKey={answer.answer_key || null}
                                answerIndex={index}
                            />
                        </div>
                    );
                })}
            </div>
            <div style={{ height: "12vh" }}>
                <div className={`nextButtonDiv ${props.DialogStore.dir === "ltr" && "dir-rtl-align-right"}`} >
                    <button
                        onClick={nextQuestion}
                        className={selected.length ? 'chose nextButton' : 'nextButton'}>
                        {Texts.NEXT_BUTTON[props.DialogStore.language]}
                    </button>
                </div>
            </div>
        </div>
    );
}

export default inject('QuestionsStore', 'DialogStore')(observer(MultiSelectContainer));
