import React, { useState, useEffect } from 'react';
import { observer, inject } from 'mobx-react';
import Auth from './../modules/auth/Auth';
import Consts from './../consts/Consts';
import Texts from '../consts/Texts';
import '../scss/_IconsNav.scss';

const MAX_SECONDS = 59;

function Timer(props) {

    const [minutes, setMinutes] = useState(props.timerMaxMinutes);
    const [seconds, setSeconds] = useState(0);

    useEffect(() => {
        let timer = null;
        if (seconds === 0 && minutes === 0) {
            if (Consts.timer_max_minutes.quiz === props.timerMaxMinutes) {
                props.DialogStore.setDialogExitStatus(Consts.exit_statuses.timeout);
            }
            //todo- do we refresh the page or redirect to google and remove it from history
            // props.PageDataStore.removePageFromHistory();
            window.location.reload();
        } else if (seconds >= 0) {
            timer = setTimeout(() => setSeconds(seconds - 1), 1000);
        } else {
            setSeconds(MAX_SECONDS);
            setMinutes(minutes - 1);
        }

        if (timer) return () => { clearTimeout(timer); }

    }, [minutes, seconds, props.dialogId]);

    const displayMinutes = minutes < 10 ? "0" + minutes : minutes;
    const displaySeconds = seconds < 10 ? "0" + seconds : seconds;

    return (
        <div className={`timerDiv ${props.DialogStore.dir === "ltr" && "dir-ltr-align-left"}`}>
            <div className='timerNote'>{Texts.TIMER_NOTE[props.DialogStore.language]}</div>
            <div className='time'>
                <img src="/images/stopwatch.svg" alt="טיימר" title="טיימר" />
                <div>{Texts.TIMER_TEXT[props.DialogStore.language]} {displayMinutes}:{displaySeconds}</div>
            </div>
        </div>
    );
}

export default inject('DialogStore', 'PageDataStore')(observer(Timer));