import React, { useState } from 'react';
import { observer, inject } from 'mobx-react';
import Auth from './../modules/auth/Auth';
import Consts from './../consts/Consts';
import HomeText from '../consts/HomeText';
//component
import IconsNav from '../components/navs/IconsNav';
import HomeContainer from '../components/containers/HomeContainer';
//style
import '../scss/HomePage.scss';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import { createMuiTheme, ThemeProvider, makeStyles } from '@material-ui/core/styles';
import { useEnableBackButton } from '../contexts/EnableBackButtonContext';
import BackButton from '../components/BackButton';

const useStylesSelect = makeStyles(theme => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));

const languages = [
    // { show: 'Français', save: 'French' },
    { show: 'עברית', save: Consts.languages.he },
    { show: 'English', save: Consts.languages.en },
    { show: 'العربية', save: Consts.languages.ar },
    { show: 'Русский', save: Consts.languages.ru },
    { show: 'አማርኛ', save: Consts.languages.am }
];

function HomePage(props) {

    const theme = createMuiTheme({ direction: 'rtl' });
    const selectClasses = useStylesSelect();
    const [currentLanguage, setCurrentLanguage] = useState(Consts.languages.he);
    const showBackButton = useEnableBackButton();

    //Start the quiz - *first the pre_quiz
    const startDialog = async () => {
        let [res, err] = await Auth.superAuthFetch('/api/Dialogs/startDialog', {
            method: 'POST',
            headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
            body: JSON.stringify({ language: currentLanguage, client: props.DialogStore.client })
        });

        if (err) { console.log("err starting dialog", err); return; }
        props.DialogStore.computedDialogId = res.dialogId;
        props.DialogStore.language = currentLanguage;
        props.DialogStore.dir = Consts.dir[currentLanguage];
        props.QuestionsStore.allQuestions = res.questions;
        props.QuestionsStore.setFirstQuestion();
        props.PageDataStore.computedPageType = Consts.page_types.QUIZ_SCREEN;
    }

    const handleChangeSelectedType = event => {
        setCurrentLanguage(event.target.value);
    };

    return (

        <div className="HomePage">
            <IconsNav />
            <div className={`firstRow ${Consts.dir[currentLanguage] === "ltr" && "dir-ltr-title-margin"}`}>
                <div className="main-title">{HomeText[currentLanguage].MAIN_TITLE}</div>
                <span className="selectLanguage">
                    <ThemeProvider theme={theme}>
                        <FormControl className={selectClasses.formControl}>
                            <Select
                                value={currentLanguage}
                                onChange={handleChangeSelectedType}
                                tanindex={0}
                            >
                                {languages.map((element, index) => {
                                    return <MenuItem value={element.save} key={index}>{element.show}</MenuItem>
                                })}
                            </Select>
                        </FormControl>
                    </ThemeProvider>
                </span>
            </div>
            <div className='containerAndButton'>
                <HomeContainer
                    className='theContainer'
                    currentLanguage={currentLanguage}
                    text={HomeText[currentLanguage].TEXT}
                />
                <button onClick={startDialog} tanindex={1}>
                    {HomeText[currentLanguage].START_BUTTON}
                </button>
            </div>
            {showBackButton ? <BackButton lang={currentLanguage} /> : null}
        </div>
    );

}

export default inject('DialogStore', 'PageDataStore', 'QuestionsStore', 'FactStore')(observer(HomePage));