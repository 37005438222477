import { observer, inject } from 'mobx-react';
import React from 'react';
import './../../scss/_IconsNav.scss';

function IconsNav(props) {


    return (
        <div className={`IconsNav`} >
            <div>
                {props.DialogStore.client === "ministry-of-health" && <img src="/images/Israeli_Ministry_of_Health_logo.png" alt="משרד הבריאות" title="משרד הבריאות" />}
                <img /* className='mainIcon'  */ src="/images/avoda.png" alt="עבודה" title="עבודה" />
                <img src="/images/joint.png" alt="גוינט" title="גוינט" />
                {/* <img src="/images/shivyon.png" alt="שוויון" title="שוויון" /> */}
                {props.DialogStore.client === "meuhedet" && <img src="/images/itzuv_logo_meuchedet_hibridi.png" alt="מאוחדת" title="מאוחדת" />}
            </div>
            <div>
                <img className='' src="/images/hilma.png" alt='הילמה- הייטק למען החברה' title='הילמה- הייטק למען החברה' />
            </div>
        </div>
    );
}

export default inject('DialogStore')(observer(IconsNav));
