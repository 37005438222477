import { observable, decorate, action } from 'mobx';
import Auth from '../modules/auth/Auth';

class SummaryStore {
    summaryText = {};


    async getSummaryText(language, for_who) {
        let [res, err] = await Auth.superAuthFetch('/api/Summaries/getSummaryText', {
            method: 'POST',
            headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
            body: JSON.stringify({ language, for_who })
        });

        if (err) { console.log("err get summary text", err); return; }

        this.summaryText = res[`${language}_${for_who}`];
    }
}

decorate(SummaryStore, {
    getSummaryText: action,
    summaryText: observable
});

export default new SummaryStore();