import { observable, decorate, action } from 'mobx';
import DialogsStore from './dialog.store';
import PageDataStore from './pageData.store';
import SummaryStore from './summary.store';
import Consts from './../consts/Consts';

class QuestionsStore {
    allQuestions = null;
    currentQuizType = null;
    currentQuizQuestions = null;
    currentQuestion = null;
    questionIndex = 0;
    isPreviousQuestion = false;

    setCurrQuestionValues = (cQuiz, questionIndex = 0) => {
        this.currentQuizType = cQuiz.quiz_type;
        this.currentQuizQuestions = cQuiz[this.currentQuizType];
        this.currentQuestion = this.currentQuizQuestions[questionIndex];
        this.questionIndex = questionIndex;
    }

    setFirstQuestion = () => {
        for (const quiz of this.allQuestions) {
            if (quiz.quiz_type !== Consts.pre_quiz) continue;
            this.setCurrQuestionValues(quiz/*, 0*/);
        }
    }

    getNextQuestion = async () => {
        this.isPreviousQuestion = false;

        //if we on pre_quiz:
        if (this.currentQuizType === Consts.pre_quiz) {

            //if we on the last pre_question:
            if (this.questionIndex === this.currentQuizQuestions.length - 1) {
                this.questionIndex = 0;

                const quizType = `${DialogsStore.localDialog.dialogData.language}_${DialogsStore.localDialog.dialogData.who_am_i}`;

                //get the quiz after pre data:
                for (const quiz of this.allQuestions) {
                    if (quiz.quiz_type !== quizType) continue;
                    this.currentQuizType = quizType;
                    this.currentQuizQuestions = quiz[quizType];
                    this.currentQuestion = quiz[quizType][0];
                }
                return;
            }

            //get next question:
            this.questionIndex = this.questionIndex + 1
            this.currentQuestion = this.currentQuizQuestions[this.questionIndex]
            return;
        }

        //if we on some quiz (after the pre):

        //if we on the last question:
        if (this.questionIndex === this.currentQuizQuestions.length - 1) {
            //get summary text
            await SummaryStore.getSummaryText(DialogsStore.localDialog.dialogData.language, DialogsStore.localDialog.dialogData.who_am_i);
            //replace page to summary
            PageDataStore.computedPageType = Consts.page_types.SUMMARY_SCREEN;
            return;
        }

        this.questionIndex = this.questionIndex + 1
        let nextQuestion = this.currentQuizQuestions[this.questionIndex]

        //normal question
        if (!nextQuestion.bound_to) {
            this.currentQuestion = nextQuestion;
            return;
        }

        //if next question bound_to another question
        let isOk = false;


        let localDialogQuestionsAnswers =DialogsStore.localDialog.questionsAnswers;
        try{
            localDialogQuestionsAnswers = JSON.parse(JSON.stringify(DialogsStore.localDialog.questionsAnswers))
        }catch(err){
            console.log('err: ', err);
        }
        for (let question in localDialogQuestionsAnswers) {
            //find the question that nextQuestion bound on it answer
            if (nextQuestion.bound_to.question_id !== localDialogQuestionsAnswers[question].question_id) continue;

            //check if the selected answers that user choose (in the answer) is in bound_to:
            for (let i in localDialogQuestionsAnswers[question].answer_index) {
                if (!nextQuestion.bound_to.answer_index.includes(localDialogQuestionsAnswers[question].answer_index[i])) continue;

                isOk = true;
                this.currentQuestion = nextQuestion;
                return;
            }
        }

        if (!isOk) this.getNextQuestion();
    }


    getPreviousQuestion = () => {
        this.isPreviousQuestion = true;
        // if: current quiz is not pre_quiz 
        //     current question is the first question of that quiz
        // then: go to last question of pre_quiz
        for (const quiz of this.allQuestions) {
            if (this.currentQuizType !== Consts.pre_quiz && this.questionIndex === 0 && quiz.quiz_type === Consts.pre_quiz) {
                const newQuestionIndex = quiz[quiz.quiz_type].length - 1;
                this.setCurrQuestionValues(quiz, newQuestionIndex);
                break;
            }

            // if we are in the middle of a quiz
            if (this.currentQuizType === quiz.quiz_type) {
                this.setCurrQuestionValues(quiz, this.questionIndex - 1);
                break;
            }
        }
        let found = false;
        for (const qa of DialogsStore.localDialog.questionsAnswers) {
            if (qa.question_id !== this.currentQuestion.question_id) continue;
            found = true;
            break;
        }
        if (!found) this.getPreviousQuestion();
    }
}


decorate(QuestionsStore, {
    questions: observable,
    currentQuestion: observable,
    getNextQuestion: action,
    getPreviousQuestion: action,
    setFirstQuestion: action,
    questionIndex: observable,
    allQuestions: observable,
    currentQuizType: observable,
    isPreviousQuestion: observable
});

let qstore = new QuestionsStore();
window.qstore = qstore;
export default qstore;
