import { observable, decorate, computed, action } from 'mobx';
import Consts from './../consts/Consts';

class PageDataStore {
    pageType = Consts.page_types.HOME_PAGE;

    get computedPageType() { return this.pageType; }
    set computedPageType(type) { this.pageType = type; }

    removePageFromHistory() {
        // Date.prototype.getTime = function () { return 626144400000 };
        window.location.replace('https://www.google.co.il/');
    }
}

decorate(PageDataStore, {
    pageType: observable,
    computedPageType: computed,
    removePageFromHistory: action
});

export default new PageDataStore();
