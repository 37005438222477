import React from 'react';
import { observer, inject } from 'mobx-react';
import Consts from '../../consts/Consts';
import Tip from '../Tip';
import '../../scss/_Container.scss';

let isMobile = false; //initiate as false   

let have_children = false;
let separate_children = false;
let together_children = false;
let after_break_up = false;
let mainTitleText = '';


function SummaryContainer(props) {

    if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(navigator.userAgent)
        || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(navigator.userAgent.substr(0, 4))) {
        isMobile = true;
    }

    const localDialogData = props.DialogStore.localDialog.dialogData;
    let risk = 1;
    if (localDialogData.danger_rating <= 10) {
        risk = 1;
    } else if (localDialogData.danger_rating > 10 && localDialogData.danger_rating <= 20) {
        risk = 2;
    } else if (localDialogData.danger_rating > 20) {
        risk = 3;
    }


    //CHILDREN:
    switch (localDialogData.have_children) {
        case Consts.summary_keys.yes_separate:
            have_children = true;
            separate_children = true;
            break;
        case Consts.summary_keys.yes_together:
            have_children = true;
            together_children = true;
            break;
        default:
            have_children = false;
            break;
    }

    //BREAK_UP_STATUS
    if (localDialogData.break_up_status === Consts.summary_keys.after_break_up) {
        after_break_up = true;
    }

    let victimGender = localDialogData.victim;
    let offenderGender = localDialogData.offender;

    if (have_children) {
        mainTitleText = props.SummaryStore.summaryText[risk] && props.SummaryStore.summaryText[risk].main_title_children[victimGender]
    } else {
        mainTitleText = props.SummaryStore.summaryText[risk] && props.SummaryStore.summaryText[risk].main_title[victimGender]
    }

    const iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

    //make contact with...
    const contact = (contact) => {
        if (isMobile) {
            if (contact.mobile.contact_type === Consts.summary_keys.contact_types.call) {
                window.location.href = "tel:" + contact.mobile.num_or_link;
            } else if (contact.mobile.contact_type === Consts.summary_keys.contact_types.web) {
                window.open(contact.mobile.num_or_link);
            } else if (contact.mobile.contact_type === Consts.summary_keys.contact_types.app) {
                if (iOS) {
                    window.open(contact.mobile.num_or_link.ios);
                } else {
                    window.open(contact.mobile.num_or_link.android);
                }
            } else if (contact.mobile.contact_type === Consts.summary_keys.contact_types.sms) {
                window.open("sms:" + contact.mobile.num_or_link);
            }
        } else {
            if (contact.computer.contact_type === Consts.summary_keys.contact_types.web) {
                window.open(contact.computer.num_or_link);
            }
        }
    }

    //add class name to the part of the text that need to be in red color.
    const formatText = (text, key = 0) => {
        if (text.split("*").length === 1) {
            return text;
        }

        let sArray = text.split("*");
        let children = [];
        let child;

        for (let i in sArray) {
            if (!sArray[i].startsWith(' ') && !sArray[i].endsWith(' ')) {
                child = <span key={i} className='haveChildren'>{sArray[i]}</span>;
            } else {
                child = <span key={i}>{sArray[i]}</span>
            }
            children.push(child);
        }

        let res = React.createElement("p", { key: key }, children);

        return res;
    }


    return (
        <div className={`SummaryContainer Container ${props.DialogStore.dir === "ltr" && "dir-ltr-align-left"}`}>
            <h4 className='mainTitle'>{mainTitleText && formatText(mainTitleText)}</h4>

            <div className='tips'>
                {
                    props.SummaryStore.summaryText[risk] && props.SummaryStore.summaryText[risk].tips.map((tip, index) => {
                        if (tip.only_when === Consts.summary_keys.together_children && !together_children) { return null; } //if she doesn't have share children - do not show.

                        else if (tip.only_when === Consts.summary_keys.separate_children && !separate_children) { return null; } //if she doesn't have personal children - do not show.

                        else if (tip.only_when === Consts.summary_keys.after_break_up && !after_break_up) { return null; } //if she isn't after break up - do not show.

                        else if (tip.only_when === 'isMobile' && !isMobile) { return null } // if we on computer do not show it.

                        else if (tip.text_children && tip.text_children[victimGender] === null || tip.text[victimGender] === null) { return null; }

                        else if (tip.only_when === 'meuhedet' && props.DialogStore.client !== 'meuhedet') { return null }

                        let tipText = (have_children && tip.text_children ? tip.text_children : tip.text) || '';
                        if (typeof tipText === 'object' && !tipText[0]) {
                            tipText = tipText[victimGender]
                        } else if (typeof tipText === 'object' && tipText[0]) {
                            let fullTipText = '';

                            for (let i in tipText) {

                                let genderText = tip.wording_for[i];
                                genderText = genderText === 'victimGender' ? genderText = victimGender : genderText = offenderGender;

                                let partOfTipText = tipText[i][genderText]
                                fullTipText = `${fullTipText} ${partOfTipText}`;
                            }

                            tipText = fullTipText;
                        }

                        return (

                            <div key={index}>
                                <Tip
                                    tipText={tipText}
                                    contactData={tip.contact ? tip.contact : false}
                                    isMobile={isMobile}
                                    contactFunction={contact}
                                />
                            </div>
                        )
                    })
                }
            </div>
        </div>
    );
}

export default inject('DialogStore', 'SummaryStore')(observer(SummaryContainer));