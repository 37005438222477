import React, { createContext, useState, useEffect, useContext } from "react";

const EnableBackButtonContext = createContext(false);
export function EnableProvider({ children }) {

    const [showBack, setShowBack] = useState(false);

    useEffect(() => {
        const url = new URL(window.location.href);
        const enableBack = url.searchParams.get('enable-back-button');
        setShowBack(!!enableBack);
    }, [])



    return <EnableBackButtonContext.Provider value={showBack}>
        {children}
    </EnableBackButtonContext.Provider>
}

export const useEnableBackButton = () => useContext(EnableBackButtonContext);