import PageDataStore from './pageData.store'
import DialogStore from './dialog.store';
import QuestionsStore from './questions.store';
import SummaryStore from './summary.store';
import FactStore from './fact.store';

export default {
    PageDataStore,
    DialogStore,
    QuestionsStore,
    SummaryStore,
    FactStore
};