export default {
    EXIT_BUTTON: {
        he: "לסגירת הטופס",
        ar: "لإغلاق الاستمارة",
        ru: "Закрыть анкету",
        en: "To close the form",
        am: " ቅጹን ወይም ፍሮሙን/ቶፌሱን ለመዝጋት"
    },
    PROGRESS: {
        he: "הושלם",
        ar: "تم",
        ru: "Заполнение анкеты завершено",
        en: "Completed",
        am: "ተሟልቷል"
    },
    MULTI_SELECT_NOTE: {
        he: "*שימו לב: ניתן לסמן יותר מתשובה אחת",
        ar: "*يرجى ملاحظة: يمكنك وضع علامة على أكثر من إجابة واحدة",
        ru: "*обратите внимание: можно отметить больше чем один ответ",
        en: "Note: You can mark more than one answer*",
        am: "ልብ ይበሉ፡ ከአንድ መልስ በላይ መስጠት ወይም ምልክት ማድረግ ይቻላል። "
    },
    NEXT_BUTTON: {
        he: "הבא",
        ar: "التالي",
        ru: "Далее",
        en: "Next",
        am: "ቀጣይ"
    },
    TIMER_TEXT: {
        he: "דקות",
        ar: "دقائق",
        ru: "минут",
        en: "minutes",
        am: "ደቂቃዎች"
    },
    TOOLTIP_GOBACK: {
        he: "חזרה לשאלה הקודמת",
        ar: "الرجوع للسؤال السابق",
        ru: "Вернуться к предыдущему вопросу",
        en: "Back to the previous question",
        am: "ወደ ቅድሙ ጥያቄ እንደገና ለመመለስ"
    },
    TIMER_NOTE: {
        he: "לביטחונך השאלון מאובטח ויסגר תוך:",
        ar: "هذه الاستمارة محميّة وبدون أسماء، وسيتم اغلاقها خلال:",
        ru: "Для вашей безопасности анкета защищена и закроется в течение",
        en: "For your security, the questionnaire is secure and will close within:",
        am: "ለአንተ ደህንነት ሲባል የቀረበ መጠይቅ፤ ሚስጥርነቱ አስተማማኝ ነው፡፡ ከ_____በኋላ ይዘጋል፡፡ "
    },
    BACK_TO_BUTTERFLY: {
        he: "חזרה לפרפר",
        en: "Go back to previous page",
        ar: "عد إلى الصفحة السابقة",
        ru: "Вернуться на предыдущую страницу",
        am: "ወደ ቀዳሚው ገጽ ተመለስ"
    }
}