import React from 'react';
import Consts from '../../consts/Consts'
//style
import '../../scss/_Container.scss';

function HomeContainer(props) {
    return (
        <div className={`HomeContainer Container ${Consts.dir[props.currentLanguage] === 'ltr' && "dir-ltr-align-left"}`}>
            {props.text.map((text, index) => {
                return <div key={index}>{text}</div>
            })}
        </div>
    );
}

export default HomeContainer;