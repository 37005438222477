import React from 'react';
import { observer, inject } from 'mobx-react';
import queryString from 'querystring';
import Consts from './../consts/Consts';

//components
import HomePage from './HomePage';
import ScreenQuiz from './ScreenQuiz';
import SummaryScreen from './SummaryScreen';

function Page(props) {
    if (window.location.origin.includes("i-risk-or"))
        props.DialogStore.client = "orthodox"
    else if (queryString.parse(props.location.search)["?client"] === "meuhedet")
        props.DialogStore.client = "meuhedet"
    else if (queryString.parse(props.location.search)["?client"] === "health")
        props.DialogStore.client = "ministry-of-health"

    if (props.PageDataStore.pageType === Consts.page_types.HOME_PAGE) {
        return (
            <HomePage />
        );
    }

    else if (props.PageDataStore.pageType === Consts.page_types.QUIZ_SCREEN) {
        return (
            <ScreenQuiz />
        );
    }

    else if (props.PageDataStore.pageType === Consts.page_types.SUMMARY_SCREEN) {
        return (
            <SummaryScreen />
        );
    }

    else {
        return (
            <div>הדף לא נמצא</div>
        );
    }

}

export default inject('DialogStore', 'PageDataStore')(observer(Page));