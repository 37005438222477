import React, { useState, useEffect } from 'react';
//components
import { observer, inject } from 'mobx-react';
import Consts from './../../consts/Consts';
import Answer from '../Answer';
import Texts from './../../consts/Texts';
//style
import '../../scss/_Container.scss';

const check_time = 300;
let timer;

function SingleSelectContainer(props) {
    const LANGUAGE = props.DialogStore.language;

    const [selected, setSelected] = useState(null);
    const [disabled, setDisabled] = useState(false);

    useEffect(() => {
        setSelected(null);
        if (timer) return () => { clearTimeout(timer); }
    }, [props.QuestionsStore.currentQuestion]);

    const nextQuestion = () => {
        if (!disabled) setDisabled(true);

        props.QuestionsStore.getNextQuestion()
        setDisabled(false);
        setSelected(null);
    }

    const setAnswerSelected = async (answerText, answerIndex, dangerRating, answerKey) => {

        if (disabled) return;
        setDisabled(true);
        // need to be on top
        await props.DialogStore.insertToDialogAndFact(
            props.QuestionsStore.currentQuestion.question_id,
            answerIndex,
            questionText,
            answerText,
            props.QuestionsStore.currentQuestion.question_key,
            answerKey
        );

        setSelected(answerIndex);
        timer = setTimeout(() => {
            nextQuestion();
        }, check_time);
    }

    const getQuestionText = () => {
        let question = props.QuestionsStore.currentQuestion.question_text;

        if (props.QuestionsStore.currentQuizType === Consts.pre_quiz) {
            if (typeof question[LANGUAGE] === "object") {
                question = question[LANGUAGE][props.DialogStore.localDialog.dialogData.who_am_i];
            } else {
                question = question[LANGUAGE];
            }
        }

        //if we have 2 diffrent gender in the question
        if (typeof question === "object" && question["0"]) {

            let allQuestionText = '';

            for (let i in question) {
                let genderText = props.DialogStore.localDialog.dialogData[props.QuestionsStore.currentQuestion.wording_for[i]]
                let partOfQuestionText = question[i][genderText]
                allQuestionText = `${allQuestionText} ${partOfQuestionText}`;
            }

            question = allQuestionText;
        }

        //if we have only one gender in the question
        else if (typeof question === "object" && !question["0"]) {
            let genderText = props.DialogStore.localDialog.dialogData[props.QuestionsStore.currentQuestion.wording_for]
            question = question[genderText];
        }

        return question;
    }

    const getDAnswerTextSelected = (answerText, wordingFor, index) => {

        let dAnswerText, dSelected;

        //if we have 2 diffrent gender in the answer
        if (typeof answerText === "object" && answerText["0"]) {

            let fullAnswerText = '';

            for (let i in answerText) {

                let genderText = props.DialogStore.localDialog.dialogData[wordingFor[i]];
                let partOfAnswerText = answerText[i][genderText]

                fullAnswerText = `${fullAnswerText} ${partOfAnswerText}`;
            }

            dAnswerText = fullAnswerText;
        }

        //if we have only one gender in the answer
        else if (typeof answerText === "object" && !answerText["0"]) {
            dAnswerText = answerText[props.DialogStore.localDialog.dialogData[wordingFor]];
        }

        else dAnswerText = answerText;

        dSelected = selected === index;

        return [dAnswerText, dSelected];
    }

    // if answer is an index of a chosen answer of previous question
    const getChosenAnswerByIndex = (index) => {
        if (!props.DialogStore.localDialog.questionsAnswers || !props.QuestionsStore.isPreviousQuestion) return false;
        for (const qa of props.DialogStore.localDialog.questionsAnswers) {
            if (qa.question_id !== props.QuestionsStore.currentQuestion.question_id) continue;

            if (qa.answer_index.includes(index) && selected !== index) {
                setSelected(index);
                return true;
            }
        }
        return false;
    }

    let questionText = getQuestionText();

    return (
        <div className={`SingleSelectContainer Container ${props.DialogStore.dir === "ltr" && "dir-ltr-align-left"}`}>
            <h1 className='mainTitle'>{questionText}</h1>
            <div className='optionalAnswers'>
                {
                    props.QuestionsStore.currentQuestion.answers.map((answer, index) => {
                        let dAnswerText, dSelected;

                        if (props.QuestionsStore.currentQuizType === Consts.pre_quiz) {
                            [dAnswerText, dSelected] = getDAnswerTextSelected(answer.text[LANGUAGE], answer.wording_for, index);
                        } else {
                            [dAnswerText, dSelected] = getDAnswerTextSelected(answer.answer_text, answer.wording_for, index);
                        }

                        if (getChosenAnswerByIndex(index)) dSelected = true;

                        return (
                            <div key={`${props.QuestionsStore.currentQuestion.question_id}${index}`}>
                                <Answer
                                    answerText={dAnswerText}
                                    setAnswerSelected={setAnswerSelected}
                                    selected={dSelected}
                                    answerKey={answer.answer_key || null}
                                    dangerRating={answer.danger_rating || 0}
                                    answerIndex={index}
                                />
                            </div>
                        );
                    })

                }
            </div>

            {props.QuestionsStore.isPreviousQuestion &&
                <div className={`nextButtonDiv ${props.DialogStore.dir === "ltr" && "dir-rtl-align-right"}`}>
                    <button
                        onClick={() => { if (!disabled) nextQuestion() }}
                        className={!disabled ? 'chose nextButton' : 'nextButton'}
                        tanindex={props.QuestionsStore.currentQuestion.answers.length + 1}
                    >
                        {Texts.NEXT_BUTTON[props.DialogStore.language]}
                    </button>
                </div>
            }
        </div>
    );
}

export default inject('QuestionsStore', 'PageDataStore', 'DialogStore')(observer(SingleSelectContainer));