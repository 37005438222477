import React from 'react';
import '../scss/_AnswerAndTip.scss';

function Tip(props) {
    return (
        <div className="Tip">
            <span className='tipText'>{props.tipText}</span>

            {(props.contactData && (props.contactData.computer !== null && !props.isMobile) || (props.contactData.mobile && props.isMobile)) &&
                <button className='conactText' onClick={() => props.contactFunction(props.contactData)}>{props.contactData.contact_text}</button>
            }
        </div>
    );
}

export default Tip;