import React from 'react';
import { observer, inject } from 'mobx-react';
import Texts from '../consts/Texts';
import '../scss/_BackButton.scss';

function BackButton(props) {

    function onClick() {
        window.history.back();
    }

    return <div className='d-flex justifiy-content-center' id="back-container">
        <button id="back-button"
            onClick={onClick}>
            {Texts.BACK_TO_BUTTERFLY[props.lang || props.DialogStore.language]}

        </button>
    </div>
}

export default inject('DialogStore')(observer(BackButton));