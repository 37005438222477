import React from 'react';
import Consts from '../consts/Consts'
import { observer, inject } from 'mobx-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//style
import '../scss/_AnswerAndTip.scss';

function Answer(props) {
    let answerText = props.answerText
    if (answerText === "מינית" && window.location.origin.includes("i-risk-or"))
        answerText = "אינטימית"
    return (
        <button
            className={`${props.selected ? "select Answer" : "Answer"} ${props.DialogStore.dir === "ltr" && "dir-ltr-align-left"}`}
            onClick={() => { props.setAnswerSelected(props.answerText, props.answerIndex, props.dangerRating, props.answerKey); }}
            tanindex={props.answerIndex + 1}
        >
            <p>{answerText}</p>
            <div className='checkIconDiv'>
                {
                    props.selected &&
                    <FontAwesomeIcon icon={["fas", "check"]} className='checkIcon' />
                }
            </div>
        </button>
    );
}

export default inject('DialogStore')(observer(Answer));