import React, { useState } from 'react';
import { observer, inject } from 'mobx-react';
import { Helmet } from 'react-helmet';
import Consts from './../../consts/Consts';
//components
import Timer from './../Timer';
import Texts from '../../consts/Texts';
//style
import './../../scss/_IconsNav.scss';
import { useEnableBackButton } from '../../contexts/EnableBackButtonContext';


function QuizTopNav(props) {
    const [title, setTitle] = useState("שאלון אבחון עצמי")
    const showBackButton = useEnableBackButton();

    const emergencyExit = async () => {
        props.DialogStore.setDialogExitStatus(Consts.exit_statuses.emergency);
        setTitle("Google");
        if (showBackButton)
            window.history.back();
        else props.PageDataStore.removePageFromHistory();
    }

    return (
        <div className={`IconsNav QuizTopNav ${props.DialogStore.dir === "ltr" && "dir-ltr-align-left QuizTopNav-ltr"}`}>
            <Helmet>
                <title>{title}</title>
            </Helmet>
            <div className='timer'>
                <Timer dialogId={props.DialogStore.dialogId} timerMaxMinutes={props.timerMaxMinutes} />
            </div>
            <button
                className={`emergencyExit ${props.DialogStore.dir === "ltr" && "emergencyExit-ltr"}`}
                onClick={emergencyExit}
                tanindex={0}
            >
                {Texts.EXIT_BUTTON[props.DialogStore.language]}
            </button>
        </div>
    );
}

export default inject('DialogStore', 'PageDataStore')(observer(QuizTopNav));
