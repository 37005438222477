import React, { useEffect } from 'react';
import { observer, inject } from 'mobx-react';
import Consts from './../consts/Consts';
//component
import IconsNav from '../components/navs/IconsNav';
import SummaryContainer from '../components/containers/SummaryContainer';
import QuizTopNav from './../components/navs/QuizTopNav';
//style
import '../scss/_SummaryScreen.scss'
import { useEnableBackButton } from '../contexts/EnableBackButtonContext';
import BackButton from '../components/BackButton';

function SummaryScreen(props) {

    useEffect(() => {
        window.scrollTo(0, 0);
        props.DialogStore.setDialogExitStatus(Consts.exit_statuses.done);
    }, []);
    const showBackButton = useEnableBackButton();

    return (
        <div className="SummaryScreen">
            <IconsNav />
            <QuizTopNav timerMaxMinutes={Consts.timer_max_minutes.summary} />
            <SummaryContainer />
            {showBackButton ? <BackButton /> : null}
        </div>
    );

}

export default inject('DialogStore', 'PageDataStore', 'SummaryStore')(observer(SummaryScreen));
