import { observable, decorate, computed, action } from 'mobx';
import Auth from '../modules/auth/Auth';
import Consts from './../consts/Consts';

class FactStore {

    // async addFact() {
    //     let [res, err] = await Auth.superAuthFetch('/api/Facts/addNewFact', {
    //         method: 'POST',
    //         headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
    //         body: JSON.stringify({})
    //     });
    //     if (err) { console.log("err from add fact", err); return; }

    //     if (res) {
    //         console.log('yaaa res!', res);
    //     }
    // }
}

decorate(FactStore, {
    // addFact: action
});

export default new FactStore();
