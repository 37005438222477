import React, { Suspense } from 'react';
import { BrowserRouter as Router, Redirect, Switch, Route } from "react-router-dom";
import Page from './scenes/Page';
import './App.scss';
import { EnableProvider } from './contexts/EnableBackButtonContext';

function App() {

    return (
        <div className="app-container">
            <Suspense fallback={<div>Loading...</div>}>
                <EnableProvider>
                    <Router>
                        <Switch>
                            <Route exact path="/" component={Page} />
                            <Redirect to='/' />
                        </Switch>
                    </Router>
                </EnableProvider>
            </Suspense>
        </div>
    );
}

export default App;