import React, { useState, useEffect } from 'react';
import { observer, inject } from 'mobx-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Texts from '../../consts/Texts';
//style
import './../../scss/_IconsNav.scss';
import './../../scss/_QuizBottomNav.scss';
import Consts from '../../consts/Consts';

const max_progress = 100

function QuizBottomNav(props) {

    const [showGoBackTooltip, setShowGoBackTooltip] = useState(true);

    useEffect(() => {
        let timer = setTimeout(() => {
            setShowGoBackTooltip(false);
        }, 1000 * 5);
        return () => { clearTimeout(timer); }
    }, []);

    let preQuizLength = 0;
    let currentQuizLength = 0;
    let quizType = null;
    if (props.DialogStore.localDialog.dialogData) quizType = `${props.DialogStore.localDialog.dialogData.language}_${props.DialogStore.localDialog.dialogData.who_am_i}`;

    for (const q of props.QuestionsStore.allQuestions) {
        if (q.quiz_type === Consts.pre_quiz) preQuizLength = q[q.quiz_type].length;
        else if (q.quiz_type === quizType || q.quiz_type === "he_i_am_victim") currentQuizLength = q[q.quiz_type].length;
    }

    let num_of_questions = preQuizLength + currentQuizLength;
    const questionIndex = props.QuestionsStore.currentQuizType === Consts.pre_quiz ? props.QuestionsStore.questionIndex : props.QuestionsStore.questionIndex + preQuizLength;
    const progressPercentage = Math.floor((questionIndex) / num_of_questions * max_progress);


    const getPreviousQuestion = () => {
        if (questionIndex) props.QuestionsStore.getPreviousQuestion();
    }

    //notice that if you change width of main-progress, you need to also change width of progress-status
    return (
        <div className={`IconsNav QuizBottomNav ${props.DialogStore.dir === "ltr" && "dir-ltr-title-margin"}`}>
            <div>
                {
                    showGoBackTooltip ?
                        <div className={`goBackTooltip  ${props.DialogStore.dir === "ltr" && "goBackTooltip-left"}`}>{Texts.TOOLTIP_GOBACK[props.DialogStore.language]}</div>
                        :
                        null
                }

                <button className="goBackButton" onClick={getPreviousQuestion} >
                    <FontAwesomeIcon
                        icon={["fas", `chevron-circle-${props.DialogStore.dir === "ltr" ? "left" : "right"}`]}
                        className={questionIndex ? 'circle-upIcon go-back-allowed' : 'circle-upIcon'}
                        title={Texts.TOOLTIP_GOBACK[props.DialogStore.language]}
                    />
                </button>
            </div>

            <div>
                <div className="progress-percent-text">{Texts.PROGRESS[props.DialogStore.language]} {progressPercentage}%</div>
                <div className='progressDiv'>
                    <div className="main-progress" style={{ width: '40vw' }} />
                    <div className="progress-status" style={{ width: progressPercentage / 2.5 + "vw" }} />
                </div>
            </div>

        </div>
    );
}

export default inject('QuestionsStore', 'DialogStore')(observer(QuizBottomNav));
