import React, { useEffect } from 'react';
import { observer, inject } from 'mobx-react';
import Consts from './../consts/Consts';
//component
import MultiSelectContainer from '../components/containers/MultiSelectContainer';
import SingleSelectContainer from '../components/containers/SingleSelectContainer';
import QuizTopNav from '../components/navs/QuizTopNav';
import QuizBottomNav from '../components/navs/QuizBottomNav';


function ScreenQuiz(props) {

    useEffect(() => { window.scrollTo(0, 0); }, []);
    let questionData = props.QuestionsStore.currentQuestion;

    return (
        <div className="ScreenQuiz">
            <QuizTopNav timerMaxMinutes={Consts.timer_max_minutes.quiz} />
            {
                questionData.question_type === 'multi' ?
                    <MultiSelectContainer
                        questionData={questionData}
                    />
                    :
                    <SingleSelectContainer />
            }
            <QuizBottomNav />
        </div>
    );

}

export default inject('QuestionsStore')(observer(ScreenQuiz));
