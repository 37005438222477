import React from 'react';

export default {
    "he": {
        "MAIN_TITLE": "שאלון זיהוי למצבי סיכון",
        "TEXT": [
            <p>שאלון זה הוא אנונימי ואינו מצריך מסירה של פרטים מזהים.</p>,
            <p>בסיומו תוכן השאלון ימחק ולא יישמר במכשיר הטלפון/המחשב.</p>,
            <p>מטרת השאלון היא לסייע לך לבחון באופן עצמאי את מצב הסיכון במערכת היחסים הזוגית שלך כיום או של בן או בת משפחה.</p>,
            <p>השאלון נכתב על ידי מומחים לטיפול כדי לזהות את מצבך או את מצב הסיכון של בן או בת משפחה.</p>,
            // <p className='boldLine'>אלימות במשפחה אינה גזירת גורל ואפשר להתמודד ולשנות את המצב.</p>,
            <p>בסיום מילוי השאלון יתקבל משוב לגבי רמת הסיכון שלך או של בן או בת משפחה והמלצות לפעולה לך ולילדים.</p>,
            <p>שאלון זה לא מחליף הערכת מסוכנות על ידי איש מקצוע מומחה.</p>,
            <p>***שאלות שלהן יש יותר מתשובה אחת רלוונטית, יש לסמן את כל מה שנכון במקרה שלך</p>
        ],
        "START_BUTTON": "התחלה"
    },
    "am": {
        "MAIN_TITLE": "አደገኛ ሁኔታ ሲገጥም የማንነትን መለያ መጠይቅ ",
        "TEXT": [
            <p>ይህ መጠይቅ ስም አልባ ነው። የማንነት መታወቂያ መረጃ ማቅረብ አያስፈልግም። </p>,
            <p>መጠይቁ እንዳለቀ ሁሉም ይሰረዛል። በኮምፒተርም ሆነ ስልክ አይቀመጥም። </p>,
            <p>የመጠይቁ ዋና ዓላማ ራስን መፈተሽ እንዲቻልና እርዳታ ማቅረብ እንዲያግዝ ነው። መጠይቁ የጋብቻ ግንኙነትዎን ወይም በቤተሰብ አባልዎ ውስጥ ያለውን የአደጋ ስጋት በተናጠል ለመመርመር የሚያግዝ ነው። </p>,
            <p>መጠይቁ የተጻፈው የቤተሰብ ግጭትን በሚፈቱ ባለሙያዎች አማካኝነት ነው። ዓላማውም እርስዎ ወይም የቤተሰብዎ አባል ያለዎትን ሁኔታ መለየት እንዲችሉ ብሎም ለእርስዎና ልጅዎ እርምጃ ለመውሰድ ምክር መለገስ እንዲቻል ነው። </p>,
            // <p className='boldLine'>אלימות במשפחה אינה גזירת גורל ואפשר להתמודד ולשנות את המצב.</p>,
            <p>መጠይቁን ከሞሉ በኋላ ለእርስዎም ሆነ ቤተሰብዎ አባል ስለሁኔታችሁ ታይቶ ማድረግ ስላለባችሁ ነገር አስተያየት ይሰጣል። </p>,
            <p>በቤተሰብ መካከል የሚፈጠር ጸብ እና አምባጓሮ ወይም አለመግባባት የእጣ ፈንታ ጉዳይ አይደለም። ሁኔታውን መቋቋም እና መለወጥ ይቻላል። </p>,
            <p>ይህ መጠይቅ ባለሙያው የሚሰጠውን የሙያ ግምገማ አደገኛነት አይተካም። </p>,
            <p>የእናንተ ጥያቄ ከአንድ አስፈላጊ ከሆነ መልስ ይበልጣል። በአንች እይታ እውነት ነው ብለሽ ያሰብሽውን ምልክት አድርጊ/አድርግ </p>
        ],
        "START_BUTTON": "መጀመሪያ"
    },
    "ar": {
        "MAIN_TITLE": "استمارة لتمييز حالات الخطر",
        "TEXT": [
            <p>هذه الاستمارة بدون أسماء</p>,
            <p> الغرض من الاستمارة التي بين يديك, مساعدتك لفحص مدى الخطورة الكامنة في علاقتك الزوجية او علاقة أي فرد من عائلتك حالياً وذلك بشكل ذاتي. تم وضع هذه الاستمارة من قبل خبراء في علاج العنف الأسري بهدف مساعدتك على تشخيص وضعك والحصول على توصيات وارشاد بكيفية التصرف بخصوصك أو بخصوص ابنة/ابن عائلتك وبخصوص الأولاد.</p>,
            <p>بعد الانتهاء من تعبئة الاستمارة سنرسل ردا تقييمياً بالنسبة لوضعك او وضع قريبك/قريبتك وتوصيات للتصرف.</p>,
            <p className='boldLine'>العنف الأسري ليس قضاءً وقدراً، بالإمكان التصدي له وتغيير الحال.</p>,
            <p>هذه الاستمارة لا تستبدل تقييم الخطورة الصادرة عن شخص مهني ومختص.</p>,
            <p>** بحال واجهتي أسئلة لها أكثر من إجابة مناسبة، ضعي علامة على كل الإجابات الصحيحة بالنسبة لك.</p>
        ],
        "START_BUTTON": "البدء"
    },
    "ru": {
        "MAIN_TITLE": "Анкета «Распознавание опасных ситуаций»",
        "TEXT": [
            <p>Это анонимная анкета, не требующая передачи идентифицирующих данных. После заполнения анкета будет удалена, и не сохранится в вашем телефоне / компьютере.</p>,
            <p>Цель анкеты - помочь вам самостоятельно проверить вероятность опасных ситуаций в ваших партнерских/семейных отношениях или партнерских/семейных отношениях члена вашей семьи. Анкета была составлена специалистами в области семейного насилия, чтобы помочь вам правильно оценить вашу ситуацию или ситуацию члена вашей семьи, а также получить рекомендации к действию для вас и ваших детей. </p>,
            // <p className='boldLine'>Насилие в семье - это не приговор судьбы, с ним можно бороться, ситуацию можно изменить.</p>,
            <p>После заполнения анкеты вы получите отзыв о вашей ситуации или ситуации члена вашей семьи, а также рекомендации к действию.</p>,
            <p>Эта анкета не является альтернативой экспертной оценки ситуации специалистом.</p>,
            <p>** В случае, если на вопрос имеется более одного правильного ответа, необходимо указать всё, что верно в вашем случае.</p>
        ],
        "START_BUTTON": "Начало"
    },
    "en": {
        "MAIN_TITLE": "Identification of Situations of Risk Questionnaire",
        "TEXT": [
            <p>The questionnaire is anonymous and does not require submission of identifying details. </p>,
            <p>At the end of it, the contents of the questionnaire will be deleted, and will not remain on the phone/ computer. </p>,
            <p>The purpose of the questionnaire is to help you examine, on your own, the risk in your current intimate (marital) partnership/ relationship or in that of a family member.</p>,
            <p>The questionnaire was prepared by experts in the treatment of domestic violence in order to help you identify your situation or that of a family member and obtain recommended courses of action for you and your children.</p>,
            <p>Once the questionnaire has been completed, you will receive feedback about your situation or that of your family member as well as recommended courses of action.</p>,
            <p>Domestic violence is not an inevitable fact, and you can address and change the situation.</p>,
            <p>This questionnaire is not a substitute for a danger assessment by an expert professional.</p>,
            <p>** For questions that have more than one relevant answer, mark all those that apply to you</p>
        ],
        "START_BUTTON": "Next"
    }
}