export default {
    page_types: {
        HOME_PAGE: 1,
        QUIZ_SCREEN: 2,
        SUMMARY_SCREEN: 3
    },
    exit_statuses: {
        emergency: 'emergency',
        timeout: 'timeout',
        done: 'done',
        other: 'other',
        // refresh: 'refresh'
    },
    timer_max_minutes: {
        quiz: 10,
        summary: 5
    },
    pre_quiz: "pre_quiz",
    languages: {
        he: "he",
        ar: "ar",
        ru: "ru",
        en: "en",
        am: "am"
    },
    dir: {
        he: "rtl",
        ar: "rtl",
        en: "ltr",
        ru: "ltr",
        am: "ltr"
    },
    user_types: {
        offender: "offender",
        victim: "victim"
    },
    summary_keys: {
        yes_separate: "yes_separate",
        yes_together: "yes_together",
        after_break_up: "after_break_up",
        together_children: "together_children",
        separate_children: "separate_children",
        after_break_up: "after_break_up",
        contact_types: {
            call: "call",
            web: "web",
            app: "app",
            sms: "sms"
        }
    }
}